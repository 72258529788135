:root {
  --border-radius: 4px;
  --warn-message-background: #fff3cd;
  --warn-message-border: 1px solid #ffeeba;
  --warn-message-color: #856404;
  --info-message-background: #cce5ff;
  --info-message-border: thin solid #b8daff;
  --info-message-color: #004085;
  --spacing-size-b: 12px;
}

.note,
.info {
  border-radius: var(--border-radius);
  line-height: 1.4em;
  padding: var(--spacing-size-b);
}

.note {
  background-color: var(--warn-message-background);
  border: var(--warn-message-border);
  color: var(--warn-message-color);
}

.info {
  background-color: var(--info-message-background);
  border: var(--info-message-border);
  color: var(--info-message-color);
}

a {
  cursor: pointer;
}

a.disabled {
  color: var(--cool-gray-30);
  cursor: not-allowed;
}

.split {
  display: flex;
  justify-content: space-between;
}
