h4 {
  border-bottom: thin solid var(--blue-70);
  color: var(--blue-70);
  display: block;
  margin-bottom: var(--spacing-3);
}

h4:hover {
  cursor: pointer;
}

page-footer {
  background-color: var(--cool-gray-10);
}

.success {
  --messageTransitionTime: 300ms;
  transition: opacity var(--messageTransitionTime) ease-in-out;
}
